<template>
  <div class="container">
    <section>
      <div class="columns mt-5" id="greenpurpleRow">
        <div class="column left-column">
          <div v-if="isLoading" style="padding: 45vh 0; text-align: center">
            Loading data from blockchain..
          </div>
          <div v-if="errored" style="padding: 45vh 0; text-align: center">
            Sorry, can't fetch NFTs right now..
          </div>

          <div class="box">
            <div class="card-image has-text-centered">
              <figure class="image is-inline-block">
                <img
                  v-if="nft.image !== undefined"
                  :src="nft.image.replace('ipfs://', API_URL + '/ipfs/')"
                  style="max-height: 500px !important; width: 100%"
                />
              </figure>
            </div>
            <div class="card-content">
              <div class="media">
                <div class="media-content" style="overflow-wrap: break-word">
                  <span class="title is-4">{{ nft.name }}</span
                  ><br />
                  <span class="subtitle is-6">{{ nft.description }}</span>
                  <hr />
                  <b>Minted by:</b><br />
                  {{ minter }}
                  <br />
                  <b>Owned by:</b><br />
                  {{ owner }}
                  <hr v-if="standardContract === '1155'" />
                  <span
                    class="subtitle is-6"
                    v-if="standardContract === '1155'"
                  >
                    You have: {{ balance }} nfts
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- If connected address is the minter, allow to mint more.
              Otherwise, show the minter address
            -->
          <div v-if="account">
            <div v-if="standardContract === '1155'">
              <div v-if="minted" class="box">
                <h3 class="mb-3"><b>Mint NFTs</b></h3>
                <b-field label="Amount to Mint">
                  <b-numberinput
                    v-model="toMint"
                    placeholder="Write the amout to transfer"
                    min="0"
                  ></b-numberinput>
                </b-field>
                <div class="has-text-centered">
                  <button v-on:click="mint" class="button violet">MINT</button>
                </div>
              </div>
              <div v-else>
                <hr />
                <b>Minted by:</b><br />
                {{ minter }}
                <br />
                <b>In your wallet you have:</b><br />
                {{ balance }} NFTs
              </div>
              <div v-if="balance > 0" class="box">
                <h3 class="mb-3"><b>Transfer NFTs</b></h3>
                <b-field label="Receiver">
                  <b-input
                    v-model="receiver"
                    placeholder="Write the receiver address"
                  >
                  </b-input>
                </b-field>
                <br />
                <b-field label="Amount to transfer">
                  <b-numberinput
                    v-model="toTransfer"
                    placeholder="Write the amout to transfer"
                    min="0"
                    :max="balance"
                  ></b-numberinput>
                </b-field>
                <div class="has-text-centered">
                  <button class="fill" v-on:click="transfer1155">
                    TRANSFER
                  </button>
                </div>
              </div>
              These are data:<br />
              {{ nft }}
              <hr />
            </div>
            <div v-if="standardContract === '721'">
              <div v-if="owner === account" class="box">
                <h3 class="mb-3"><b>Transfer NFT</b></h3>
                <b-field label="Receiver">
                  <b-input
                    v-model="receiver"
                    placeholder="Write the receiver address"
                  >
                  </b-input>
                </b-field>
                <br />
                <div class="has-text-centered">
                  <button class="fill" v-on:click="transfer721">
                    TRANSFER
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column" v-if="account">
          <div class="has-text-start right-column">
            <div class="b-bottom">
              <div class="px-4 mb-5">
                <div class="card card-q d-block">
                  <h3><strong>Control Panel</strong></h3>
                  <p>
                    Wallet in use:
                    <a
                      :href="'https://explorer.quadrans.io/address/' + account"
                      target="_blank"
                      >{{ account }}</a
                    >
                  </p>
                  <!-- As of now this link will birng user to all minted and
                      owned NFTs, thus may be better to change the button label
                      from "Minted NFT" to "Your NFTs"
                    -->
                  <hr />
                  <div class="text-center">
                      <a :href="'/nfts/' + account">
                        <button class="button violet">Your NFTs</button>
                      </a>
                      <a :href="'/profile/' + account">
                        <button class="button violet">Public Gallery</button>
                      </a>
                    </div>
                </div>

                <div class="fake-browser">
                  <header class="fake-browser-header">
                    <div class="action-btns">
                      <span></span>
                      <span></span> <span></span>
                    </div>
                    <small><strong>Minting Console</strong></small>
                  </header>
                  <section class="fake-window-body">
                    <div
                      style="
                        list-style-type: none;
                        font-family: 'Roboto Mono', monospace;
                        font-size: 14px;
                        line-height: 25px;
                        padding-left: 5px;
                        background-color: #262951 !important;
                        color: #e7e9db !important;
                      "
                      id="printLog"
                    ></div>
                    <span class="blinking-cursor"></span>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
var Web3 = require("web3");
const ABI_1155 = require("../util/abi1155.json");
const ABI_721 = require("../util/abi721.json");
const axios = require("axios");
const { ethers } = require("ethers");

export default {
  name: "Nft",
  data() {
    return {
      web3: "",
      contractAddress: "",
      isContractChecked: "",
      standardContract: "",
      account: "",
      contract: {},
      axios: axios,
      isLoading: true,
      errored: false,
      nft: {},
      receiver: "",
      minter: "",
      owner: "",
      amount: 1,
      balance: 0,
      toMint: 0,
      created: [],
      minted: false,
      ipfsHash: "",
      toTransfer: 0,
      API_URL: process.env.VUE_APP_IPFS_ENDPOINT,
    };
  },
  mounted() {
    this.connect();
  },
  methods: {
    printLog(message, value = "") {
      console.log(message, value);
      if (this.account !== undefined && this.account.length > 0) {
        document.getElementById("printLog").innerHTML =
          document.getElementById("printLog").innerHTML +
          "<div class='console-margin'>" +
          message +
          "<br> " +
          value;
        this.scrollToEnd();
      }
    },
    async connect() {
      const app = this;
      if (window.ethereum !== undefined) {
        app.web3 = new Web3(window.ethereum);
        window.ethereum.on("accountsChanged", (accounts) => {
          app.connect();
        });
        try {
          let accounts = await app.web3.eth.getAccounts();
          if (accounts[0] !== undefined) {
            app.account = accounts[0];
            let contractChecked = "";
            let standardContract = "";
            if (app.$route.params.contract !== undefined) {
              const contractDetails = app.$route.params.contract.split(":");
              contractChecked = contractDetails[0];
              standardContract = contractDetails[1];
            } else {
              contractChecked = "0x970e7BC2bea44f3796d9D895496e2C50a3E61652";
              standardContract = "721";
            }
            const netId = await app.web3.eth.net.getId();
            if (parseInt(netId) === parseInt(process.env.VUE_APP_NETWORK_ID)) {
              if (
                contractChecked !== null &&
                contractChecked !== undefined &&
                contractChecked.length > 0
              ) {
                app.isContractChecked = true;
                app.contractAddress = contractChecked;
                app.standardContract = standardContract;
                if (standardContract === "1155") {
                  app.contract = await new app.web3.eth.Contract(
                    ABI_1155,
                    app.contractAddress,
                    {
                      gasLimit: "5000000",
                    }
                  );
                  app.fetch1155();
                } else if (standardContract === "721") {
                  app.contract = await new app.web3.eth.Contract(
                    ABI_721,
                    app.contractAddress,
                    {
                      gasLimit: "5000000",
                    }
                  );
                  app.fetch721();
                }
              } else {
                app.isContractChecked = false;
              }
            } else {
              await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: "0x2AC2",
                    chainName: "Quadrans",
                    rpcUrls: ["https://rpc.quadrans.io"],
                    nativeCurrency: {
                      name: "Quadrans Coin",
                      symbol: "QDC",
                      decimals: 18,
                    },
                    blockExplorerUrls: ["https://explorer.quadrans.io/"],
                  },
                ],
              });
              app.connect();
            }
          } else {
            app.connectPublic();
          }
        } catch {}
      } else {
        app.connectPublic();
      }
    },
    async connectPublic() {
      const app = this;
      const provider = new ethers.providers.JsonRpcProvider(
        process.env.VUE_APP_WEB3_PROVIDER
      );
      const wallet = new ethers.Wallet(process.env.VUE_APP_DUMMY_KEY).connect(
        provider
      );
      let contractChecked = "";
      let standardContract = "";
      if (app.$route.params.contract !== undefined) {
        const contractDetails = app.$route.params.contract.split(":");
        contractChecked = contractDetails[0];
        standardContract = contractDetails[1];
      } else {
        contractChecked = "0x970e7BC2bea44f3796d9D895496e2C50a3E61652";
        standardContract = "721";
      }
      app.contractAddress = contractChecked;
      app.standardContract = standardContract;
      if (standardContract === "1155") {
        app.contract = new ethers.Contract(
          app.contractAddress,
          ABI_1155,
          wallet
        );
        try {
          app.created = await app.contract.created(app.account);
          app.printLog("You created those NFTs:");
          app.printLog(JSON.stringify(app.created));
          if (app.created.indexOf(app.$route.params.tokenId) !== -1) {
            app.minted = true;
            app.printLog("Connected address is minter of NFT");
          }
          const metadata = await app.contract._idToMetadata(
            app.$route.params.tokenId
          );
          // Retreive the minter of the NFT
          app.minter = await app.contract.methods
            .returnCreatorByHash(metadata)
            .call();
          app.ipfsHash = metadata;
          try {
            app.printLog("Downloading metadata from " + metadata);
            const json = await app.axios.get(
              process.env.VUE_APP_API_URL + "/ipfs/" + metadata
            );
            json.data.tokenId = app.$route.params.tokenId;
            app.isLoading = false;
            app.nft = json.data;
            app.printLog("-> Metadata fetched correctly.");
            app.balance = await app.contract.balanceOf(
              app.account,
              app.$route.params.tokenId
            );
            app.printLog(
              "-> Balance of " +
                app.account +
                " - " +
                app.$route.params.tokenId +
                " is " +
                app.balance
            );
          } catch (e) {
            app.printLog("-> Can't fetch metadata from " + metadata);
          }
        } catch (e) {
          app.isLoading = false;
          app.errored = true;
        }
      } else {
        app.contract = new ethers.Contract(
          app.contractAddress,
          ABI_721,
          wallet
        );
        try {
          const metadata = (
            await app.contract.tokenURI(app.$route.params.tokenId)
          ).replace("ipfs://", "");
          app.ipfsHash = metadata;
          // Retreive the minter of the NFT
          app.minter = await app.contract.returnCreatorByHash(metadata);
          console.log("MINTER", app.minter);
          // Retreive the owner of the NFT
          app.owner = await app.contract.ownerOf(app.$route.params.tokenId);
          console.log("OWNER", app.owner);
          try {
            app.printLog("Downloading metadata from " + metadata);
            const json = await app.axios.get(
              process.env.VUE_APP_API_URL + "/ipfs/" + metadata
            );
            json.data.tokenId = app.$route.params.tokenId;
            app.isLoading = false;
            app.nft = json.data;
          } catch (e) {
            console.log(e);
            app.printLog("-> Can't fetch metadata from " + metadata);
          }
        } catch (e) {
          console.log(e);
          app.isLoading = false;
          app.errored = true;
        }
      }
    },
    async fetch1155() {
      const app = this;
      window.ethereum.enable();
      let accounts = await app.web3.eth.getAccounts();
      app.account = accounts[0];
      app.contractAddress = process.env.VUE_APP_CONTRACT_ADDRESS;
      try {
        app.created = await app.contract.methods.created(app.account).call();
        // Retreive the minter of the NFT
        app.minter = await app.contract.methods
          ._creators(app.$route.params.tokenId)
          .call();
        app.printLog("You created those NFTs:");
        app.printLog(JSON.stringify(app.created));
        if (app.created.indexOf(app.$route.params.tokenId) !== -1) {
          app.minted = true;
          app.printLog("Connected address is minter of NFT");
        }
        const metadata = await app.contract.methods
          ._idToMetadata(app.$route.params.tokenId)
          .call();
        app.ipfsHash = metadata;
        try {
          app.printLog("Downloading metadata from " + metadata);
          const json = await app.axios.get(
            process.env.VUE_APP_API_URL + "/ipfs/" + metadata
          );
          json.data.tokenId = app.$route.params.tokenId;
          app.isLoading = false;
          app.nft = json.data;
          app.printLog("-> Metadata fetched correctly.");
          app.balance = await app.contract.methods
            .balanceOf(app.account, app.$route.params.tokenId)
            .call();
          app.printLog(
            "-> Balance of " +
              app.account +
              " - " +
              app.$route.params.tokenId +
              " is " +
              app.balance
          );
        } catch (e) {
          app.printLog("-> Can't fetch metadata from " + metadata);
        }
      } catch (e) {
        app.isLoading = false;
        app.errored = true;
      }
    },
    async fetch721() {
      const app = this;
      window.ethereum.enable();
      let accounts = await app.web3.eth.getAccounts();
      app.account = accounts[0];
      app.contractAddress = process.env.VUE_APP_CONTRACT_ADDRESS;
      try {
        app.owner = await app.contract.methods
          .ownerOf(app.$route.params.tokenId)
          .call();
        const metadata = (
          await app.contract.methods.tokenURI(app.$route.params.tokenId).call()
        ).replace("ipfs://", "");
        app.minter = await app.contract.methods
          .returnCreatorByHash(metadata)
          .call();
        app.ipfsHash = metadata;
        try {
          app.printLog("Downloading metadata from " + metadata);
          const json = await app.axios.get(
            process.env.VUE_APP_API_URL + "/ipfs/" + metadata
          );
          json.data.tokenId = app.$route.params.tokenId;
          app.isLoading = false;
          app.nft = json.data;
        } catch (e) {
          app.printLog("-> Can't fetch metadata from " + metadata);
        }
      } catch (e) {
        app.isLoading = false;
        app.errored = true;
      }
    },
    async mint() {
      const app = this;
      if (app.toMint > 0) {
        await app.contract.methods
          .mint(app.ipfsHash, app.toMint)
          .send({ from: app.account })
          .on("transactionHash", (tx) => {
            app.printLog("Waiting for transaction to be confirmed at: " + tx);
          });
        app.balance = await app.contract.methods
          .balanceOf(app.account, app.$route.params.tokenId)
          .call();
        app.printLog(
          "-> Balance of " +
            app.account +
            " - " +
            app.$route.params.tokenId +
            " is " +
            app.balance
        );
        alert("Minted correctly!");
      }
    },
    async transfer1155() {
      const app = this;
      if (app.toTransfer > 0) {
        await app.contract.methods
          .safeTransferFrom(
            app.account,
            app.receiver,
            app.$route.params.tokenId,
            app.toTransfer,
            []
          )
          .send({ from: app.account });

        app.balance = await app.contract.methods
          .balanceOf(app.account, app.$route.params.tokenId)
          .call();
        app.printLog(
          "-> Balance of " +
            app.account +
            " - " +
            app.$route.params.tokenId +
            " is " +
            app.balance
        );
        alert("Transferred correctly!");
      }
    },
    async transfer721() {
      const app = this;
      try {
        await app.contract.methods
          .safeTransferFrom(
            app.account,
            app.receiver,
            app.$route.params.tokenId
          )
          .send({ from: app.account })
          .on("transactionHash", (tx) => {
            app.printLog("Pending transaction: " + tx);
          });
        alert("Transferred correctly!");
        app.connect();
      } catch (e) {
        alert(e.message);
      }
    },
    scrollToEnd() {
      var container = document.querySelector(".fake-window-body");
      var scrollHeight = container.scrollHeight;
      container.scrollTop = scrollHeight;
    },
    mounted() {
      this.scrollToEnd();
    },
    updated() {
      this.scrollToEnd();
    },
  },
};
</script>

<style scoped>
</style>
