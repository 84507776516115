<template>
  <div>
    <div v-show="account" class="container">
      <section>
        <h3 class="mt-5"><b>NFTs owned by</b> {{ $route.params.address }}</h3>
        <div class="columns is-mobile mt-5" id="greenpurpleRow">
          <div class="
              column
              is-half is-three-fifths-tablet is-full-mobile
              left-column
            ">
            <div v-if="isLoading" style="padding: 45vh 0; text-align: center">
              Loading data from blockchain..
            </div>
            <div v-if="errored" style="padding: 45vh 0; text-align: center">
              Sorry, can't fetch NFTs right now..
            </div>
            <div v-if="!isLoading && nfts.length > 0" class="columns">
              <div class="column">
                <div v-for="nft in nfts" v-bind:key="nft.tokenId" class="card card-q p-3">
                  <div class="card-image has-text-centered" style="padding-top:50px">
                    <figure class="image is-inline-block">
                      <a :href="
                        '/nfts/' + nft.contract + ':' + nft.standard + '/' + $route.params.address + '/' + nft.tokenId
                      ">
                        <img :src="
                          nft.image.replace('ipfs://', API_URL + '/ipfs/')
                        " style="max-height: 500px !important; width: 100%" />
                      </a>
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <a :href="
                          '/nfts/' + nft.contract + ':' + nft.standard + '/' + $route.params.address + '/' + nft.tokenId
                        ">
                          <span class="title is-4">{{ nft.name }}</span><br />
                          <span class="subtitle is-6">{{
                            nft.description
                          }}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!isLoading && nfts.length === 0">
              Sorry, you don't have any nft on your wallet.
            </div>
          </div>
          <div class="column">
            <div class="has-text-start right-column">
              <div class="b-bottom">
                <div class="px-4 mb-5">
                  <div class="card card-q d-block">
                    <h3><strong>Control Panel</strong></h3>
                    <p>
                      Wallet in use:
                      <a :href="
                        'https://explorer.quadrans.io/address/' + account
                      " target="_blank">{{ account }}</a>
                    </p>
                    <div v-if="account && isContractChecked">
                      <p>
                        Contract in use:
                        <a :href="
                          'https://explorer.quadrans.io/address/' +
                          contractAddress
                        " target="_blank">{{ contractAddress }}</a>
                      </p>
                      <b-button v-if="false" class="mt-4" @click="changeContract()">Change Contract</b-button>
                    </div>
                    <hr />
                    <b-button v-if="false" class="mt-4" type="button violet" @click="changeContract()">Change
                      Contract</b-button>
                    <!-- As of now this link will bring user to all minted and
                      owned NFTs, thus may be better to change the button label
                      from "Minted NFT" to "Your NFTs"
                    -->
                    <div class="text-center">
                      <a :href="'/nfts/' + account">
                        <button class="button violet">Your NFTs</button>
                      </a>
                      <a :href="'/profile/' + account">
                        <button class="button violet">Public Gallery</button>
                      </a>
                    </div>
                  </div>
                  <!--
                  <div v-if="preview && show" class="img_container mt-4">
                    <div class="card card-q">
                      <img :src="preview" />
                    </div>
                  </div>
                  -->
                  <div class="fake-browser">
                    <header class="fake-browser-header">
                      <div class="action-btns">
                        <span></span>
                        <span></span> <span></span>
                      </div>
                      <small><strong>Minting Console</strong></small>
                    </header>
                    <section class="fake-window-body">
                      <div
                        style="list-style-type: none;font-family: 'Roboto Mono', monospace;font-size: 14px;line-height: 25px;padding-left: 5px;background-color: #262951 !important;color: #e7e9db !important;"
                        id="printLog"></div>
                      <span class="blinking-cursor"></span>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-if="!account" class="
        is-flex is-justify-content-center is-align-items-center is-full-mobile
      " style="height: 80vh">
      <div class="has-text-centered mt-5">
        <img src="https://quadrans.io/assets/brand/logo_quadrans_color.svg" width="250" style="margin: 25px 0 0 0" />
        <h1>
          Please connect your Metamask wallet first,<br />window should be open
          automatically or click below button.<br /><br />
        </h1>
        <b-button v-on:click="connect">CONNECT METAMASK</b-button>
      </div>
    </div>
  </div>
</template>

<script>
var Web3 = require("web3");
const ABI_1155 = require("../util/abi1155.json");
const ABI_721 = require("../util/abi721.json");
const axios = require("axios");

export default {
  name: "Mint",
  data() {
    return {
      web3: new Web3(window.ethereum),
      isContractChecked: "",
      standardContract: "",
      contractAddress: "",
      account: "",
      contract: {},
      axios: axios,
      isLoading: true,
      errored: false,
      nfts: [],
      tokenIds: [],
      API_URL: process.env.VUE_APP_IPFS_ENDPOINT,
    };
  },
  mounted() {
    this.connect();
  },
  methods: {
    /**
     * @brief Get all nft transfered to a given ownerAddress and not yet
     * transfered again to someone other
     */
    /* NOTE: This implementation relies on client-side features only, thus
     * it is extremely slow and should be used only for testing/demo
     * purposes
     */
    async receivedAndOwned(contractObj, ownerAddress) {
      // Get all NFTs transfered to the given owner
      const transferedToOwner = await contractObj.getPastEvents(
        "TransferSingle",
        {
          filter: {
            to: ownerAddress,
          },
          fromBlock: process.env.VUE_APP_FILTER_BLOCKNUMBER_START,
          // For speeding up a little bit this
          // is fixed to the very last block
          // before contract deployment.
          // See app parameters for configuring this
          // value accordingly
          toBlock: "latest",
        }
      );
      // Get all NFTs transfered by the given owner
      const transferedFromOwner = await contractObj.getPastEvents(
        "TransferSingle",
        {
          filter: {
            from: ownerAddress,
          },
          fromBlock: process.env.VUE_APP_FILTER_BLOCKNUMBER_START,
          // The same as above
          toBlock: "latest",
        }
      );
      console.log(transferedFromOwner);
      // prune from minted tokens (seems that a TransferSingle events is
      // emitted from 0x0000.000 to owner  ), then map event objects to a
      // simpler object
      let owned = await transferedToOwner
        .filter((evt) => {
          if (
            evt.returnValues.from !==
            "0x0000000000000000000000000000000000000000"
          ) {
            return true;
          }
        })
        .map((evt) => {
          // Just take the tokenID and the number of token transfered
          return {
            id: evt.returnValues.id,
            value: evt.returnValues.value,
          };
        });
      // Remove duplicate entries
      let ownedNoDuplicates = [];
      await owned.forEach((token) => {
        const foundIdx = ownedNoDuplicates.findIndex((tk) => {
          return tk.id === token.id;
        });
        console.log(foundIdx);
        if (foundIdx >= 0) {
          // We have already added this token, just increase its value
          ownedNoDuplicates[foundIdx].value += token.value;
        } else {
          // First time we see this token, let's add it
          ownedNoDuplicates.push(token);
        }
      });

      // now remove compute the "balance" (i.e. effectively owned tokens) by
      // decreasing the balance of each token by amount transfered out.
      // Then remove all tokenID having balance === 0, and take just tokenID
      // of the remaining
      const tokenIds = await ownedNoDuplicates
        .map((token) => {
          const found = transferedFromOwner.find((evt) => {
            return evt.returnValues.id === token.id;
          });
          if (found) {
            token.value -= found.returnValues.value;
          }
          return token;
        })
        .filter((token) => {
          return token.value > 0;
        })
        .map((token) => {
          return token.id;
        });

      return tokenIds;
    },
    async connect() {
      const app = this;
      window.ethereum.enable();
      window.ethereum.on("accountsChanged", (accounts) => {
        app.connect();
      });
      try {
        let accounts = await app.web3.eth.getAccounts();
        app.account = accounts[0];
        const netId = await app.web3.eth.net.getId();
        console.log("NETWORK_ID", netId);
        if (parseInt(netId) === parseInt(process.env.VUE_APP_NETWORK_ID)) {
          const erc721Contracts =
            process.env.VUE_APP_721_CONTRACT_ADDRESSES.split(",");
          const erc1155Contracts =
            process.env.VUE_APP_1155_CONTRACT_ADDRESSES.split(",");
          if (erc1155Contracts.length > 0) {
            for (let k in erc721Contracts) {
              if (erc721Contracts[k].length > 0) {
                console.log("Fetching NFTs from 721:", erc721Contracts[k]);
                app.fetch721(erc721Contracts[k]);
              }
            }
          }
          if (erc1155Contracts.length > 0) {
            for (let k in erc1155Contracts) {
              if (erc1155Contracts[k].length > 0) {
                console.log("Fetching NFTs from 1155:", erc1155Contracts[k]);
                app.fetch1155(erc1155Contracts[k]);
              }
            }
          }
          setTimeout(function () {
            app.isLoading = false;
          }, 3000);
        } else {
          if (parseInt(process.env.VUE_APP_NETWORK_ID) === 1) {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: "0x2AC2",
                  chainName: "Quadrans",
                  rpcUrls: ["https://rpc.quadrans.io"],
                  nativeCurrency: {
                    name: "Quadrans Coin",
                    symbol: "QDC",
                    decimals: 18,
                  },
                  blockExplorerUrls: ["https://explorer.quadrans.io/"],
                },
              ],
            });
          } else {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: "0x2AC3",
                  chainName: "Quadrans Testnet",
                  rpcUrls: ["https://rpctest.quadrans.io"],
                  nativeCurrency: {
                    name: "Testnet Quadrans Coin",
                    symbol: "tQDC",
                    decimals: 18,
                  },
                  blockExplorerUrls: ["https://explorer.testnet.quadrans.io/"],
                },
              ],
            });
          }
          app.connect();
        }
      } catch { }
    },
    async fetch1155(contract_address) {
      const app = this;
      window.ethereum.enable();
      let accounts = await app.web3.eth.getAccounts();
      app.account = accounts[0];
      try {
        let contract = await new app.web3.eth.Contract(
          ABI_1155,
          contract_address);
        let created = await contract.methods
          .created(app.$route.params.address)
          .call();
        let received = await app.receivedAndOwned(
          contract,
          app.$route.params.address
        );
        console.log("Created NFTs:", created);
        console.log("Received NFTs:", received);
        let all = created.concat(received);

        for (let k in all) {
          const metadata = await contract.methods._idToMetadata(all[k]).call();
          try {
            console.log("Downloading metadata from " + metadata);
            const json = await app.axios.get(
              process.env.VUE_APP_API_URL + "/ipfs/" + metadata
            );
            json.data.tokenId = all[k];
            json.data.contract = contract_address;
            json.data.standard = 1155;
            app.isLoading = false;
            console.log("TokenId:", all[k])
            if (app.tokenIds.indexOf(json.data.tokenId) === -1) {
              app.tokenIds.push(json.data.tokenId)
              app.nfts.unshift(json.data);
              console.log("-> Metadata fetched correctly.");
            }
          } catch (e) {
            console.log("-> Can't fetch metadata from " + metadata);
          }
        }
      } catch (e) {
        console.log("ERRORED", e)
        app.isLoading = false;
        app.errored = true;
      }
    },
    async fetch721(contract_address) {
      const app = this;
      window.ethereum.enable();
      let accounts = await app.web3.eth.getAccounts();
      app.account = accounts[0];
      try {
        let contract = await new app.web3.eth.Contract(
          ABI_721,
          contract_address);
        let supply = await contract.methods.totalSupply().call();

        for (let k = 1; k <= supply; k++) {
          const owner = await contract.methods.ownerOf(k).call();
          console.log("Owner is:", owner);
          if (owner.toUpperCase() === app.account.toUpperCase()) {
            const metadata = (
              await contract.methods.tokenURI(k).call()
            ).replace("ipfs://", "");
            try {
              console.log("Downloading metadata from " + metadata);
              const json = await app.axios.get(
                process.env.VUE_APP_API_URL + "/ipfs/" + metadata
              );
              json.data.tokenId = k;
              json.data.contract = contract_address;
              json.data.standard = 721;
              app.isLoading = false;
              if (app.tokenIds.indexOf(json.data.tokenId) === -1) {
                app.tokenIds.push(json.data.tokenId)
                app.nfts.unshift(json.data);
                console.log("-> Metadata fetched correctly.");
              }
            } catch (e) {
              console.log("-> Can't fetch metadata from " + metadata);
            }
          }
        }
      } catch (e) {
        console.log("ERROR", e.message);
        app.isLoading = false;
        app.errored = true;
      }
    },
  },
};
</script>

<style scoped>
#printLog {
  word-break: break-all;
}
</style>
