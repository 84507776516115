<template>
  <div id="app">
    <Header />

    <div id="navigation">
      <nav class="navbar navbar-light navbar-expand-lg py-0 fixed-top">
        <a class="navbar-brand" href="/">
          <div id="logo">
            <img src="https://quadrans.foundation/assets/brand/logo_quadrans_color.svg" alt="Quadrans Foundation"
              class="logo">
          </div>
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <!-- START NAVBAR LINKS -->

        <div class="collapse navbar-collapse mt-auto" id="navbarSupportedContent">
          <ul class="navbar-nav ml-lg-3 mt-auto mr-auto">
            <li class="nav-item text-center">
              <router-link to="/" class="nav-link">Home<div></div></router-link>
            </li>
            <li class="nav-item text-center d-none">
              <router-link to="/gallery" class="nav-link">Gallery<div></div></router-link>
            </li>
            <li v-if="account" class="nav-item text-center">
              <router-link :to="'/nfts/' + account" class="nav-link">Your NFTs<div></div></router-link>
            </li>
            <li class="nav-item text-center d-none">
              <router-link to="/about" class="nav-link">How it works<div></div></router-link>
            </li>
          </ul>
          <a href="https://community.quadrans.io" class="nav-link mb-aut text-center violet">Community Hub <i
              class="ml-2 fas fa-external-link-alt"></i></a>
          <a href="https://quadrans.foundation" class="nav-link mb-aut text-center violet">Foundation <i
              class="ml-2 fas fa-external-link-alt"></i></a>
        </div>
        <!-- END NAVBAR LINKS -->
      </nav>
    </div>
    <!-- EMPTY ROW START -->
    <div class="w-100 d-print-none" style="height:80px"></div>
    <!-- EMPTY ROW END -->

    <router-view />

    
    <footer class="mt-4">
      <div id="footerMiddle" class="pt-4 d-print-none">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 my-1 col-xl-7">
              <div class="row">
                <div class="col-12 col-lg-6 col-xl-5 mb-3  text-center text-lg-left">
                  <img src="https://quadrans.foundation/assets/brand/logo_quadrans_white.svg" alt="Quadrans Foundation" style="height: 55px;">
                </div>
                <div class="col-12 col-lg-6 col-xl-7 mb-3 text-center text-lg-left">
                  <a href="https://www.linkedin.com/company/quadrans-foundation/" title="LinkedIn" target="_blank">
                    <div class="circle-violet inline-block text-center">
                      <i class="fab fa-linkedin-in" aria-hidden="true"></i>
                    </div>
                  </a>
                  <a href="https://www.facebook.com/quadransfoundation/" title="Facebook" target="_blank">
                    <div class="circle-violet inline-block text-center">
                      <i class="fab fa-facebook-f" aria-hidden="true"></i>
                    </div>
                  </a>
                  <a href="https://twitter.com/quadrans" title="Twitter" target="_blank">
                    <div class="circle-violet inline-block text-center">
                      <i class="fab fa-twitter" aria-hidden="true"></i>
                    </div>
                  </a>
                  <a href="https://t.me/quadrans" title="Telegram" target="_blank">
                    <div class="circle-violet inline-block text-center">
                      <i class="fab fa-telegram-plane" aria-hidden="true"></i>
                    </div>
                  </a>
                  <br class="d-none d-lg-block">
                  <a href="https://github.com/quadrans/" title="GitHub" target="_blank">
                    <div class="circle-violet inline-block text-center">
                      <i class="fab fa-github" aria-hidden="true"></i>
                    </div>
                  </a>
                  <a href="https://reddit.com/r/quadrans" title="Reddit" target="_blank">
                    <div class="circle-violet inline-block text-center">
                      <i class="fab fa-reddit-alien" aria-hidden="true"></i>
                    </div>
                  </a>
                  <a href="https://discord.gg/KTp8J2X2q6" title="Discord" target="_blank">
                    <div class="circle-violet inline-block text-center">
                      <i class="fab fa-discord" aria-hidden="true"></i>
                    </div>
                  </a>
                  <a href="https://instagram.com/quadransblockchain" title="Instagram" target="_blank">
                    <div class="circle-violet inline-block text-center">
                      <i class="fab fa-instagram" aria-hidden="true"></i>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-12 my-1 col-xl-5">
              <div class="row">
                <div class="col-12 col-sm-6 text-center text-lg-left pl-lg-4">
                  <p class="text-light"><b>Ecosystem</b></p>
                  <p class="text-light"><a href="https://quadrans.io/" target="_blank" class="text-white">Quadrans Blockchain</a></p>
                  <p class="text-light"><a href="https://quadrans.foundation/grant" target="_blank" class="text-white">Grant Programs</a></p>
                  <p class="text-light"><a href="https://quadrans.io/mining" target="_blank" class="text-white">Mining</a></p>
                </div>
                <div class="col-12 col-sm-6 text-center text-lg-left pl-lg-4">
                  <p class="text-light"><b>About Quadrans</b></p>
                  <p class="text-light"><a href="https://quadrans.foundation/contacts" target="_blank" class="text-white">Contacts</a></p>
                  <p class="text-light"><a href="https://quadrans.foundation/privacy-policy" target="_blank" class="text-white">Privacy Policy</a></p>
                  <p class="text-light"><a href="https://quadrans.foundation/cookies-policy" target="_blank" class="text-white">Cookies</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="footerBottom">
        <div class="container-fluid-x">
          <div class="row">
            <div class="col">
              <p class="text-center my-3" style="line-height: 1.1rem !important;"><small class="text-light">Copyright ©
                  2018-{{ new Date().getFullYear() }} <b>Quadrans Foundation</b> | Via alla Torre n.2, 6850 Mendrisio – Switzerland | CHE
                  432.155.979&nbsp;<br>
                  The "Q" symbol is a registered trademark of Quadrans Foundation | e-mail:
                  <a>fondazione@quadrans.io</a></small>
              </p>
            </div>
          </div>
        </div>
      </div>

    </footer>


  </div>
</template>

<script>
var Web3 = require("web3");

export default {
  name: "App",
  data() {
    return {
      account: "",
      web3: new Web3(window.ethereum),
    }
  },
  mounted() {
    this.connect()
  },
  methods: {
    async connect() {
      const app = this;
      if (window.ethereum !== undefined) {
        console.log("Connecting..")
        try {
          window.ethereum.enable();
          window.ethereum.on("accountsChanged", (accounts) => {
            app.connect();
          });
          let accounts = await app.web3.eth.getAccounts();
          app.account = accounts[0];
        } catch (e) {
          console.log("Error while connecting", e)
        }
      }
    },
  }
};
</script>

<style>
/* Testnet wallpaper
*/
body {
  background-image: url(https://quadrans.io/assets/img/testnet.png);
  background-repeat: repeat;
}
#nav a.router-link-exact-active {
  color: #42b983;
}

.navbar {
  position: fixed !important;
}

.console-log {
  border-left: 1px solid #ccc;
  text-align: left;
  font-family: "Source Code Pro", monospace;
  font-size: 12px;
}

.subtitle {
  white-space: pre-line;
}

#printLog {
  word-break: break-all;
}

.fake-browser {
  /* width: 860px; */
  height: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0px 10px #ccc;
}

.fake-browser .fake-browser-header {
  background-color: #eee;
  height: 44px;
  display: flex;
  align-items: center;
}

.fake-browser .fake-browser-header .action-btns {
  min-width: 100px;
}

.fake-browser .fake-browser-header .action-btns span {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-left: 10px;
  border-radius: 50%;
}

.fake-browser .fake-browser-header .action-btns span:first-child {
  background-color: red;
}

.fake-browser .fake-browser-header .action-btns span:nth-child(2) {
  background-color: orange;
}

.fake-browser .fake-browser-header .action-btns span:nth-child(3) {
  background-color: green;
}

.fake-browser .fake-browser-header .address-bar {
  background-color: white;
  padding: 4px 10px;
  border-radius: 4px;
  flex: 1;
}

.fake-browser .fake-browser-header .address-bar input {
  width: 100%;
  border: none;
  padding: 0;
  outline: none;
  font-size: 12px;
}

.fake-browser .fake-browser-header .setting-more {
  min-width: 100px;
  text-align: right;
  padding-right: 15px;
}

.fake-browser .fake-browser-header .setting-more .more-btn {
  position: relative;
}

.fake-browser .fake-browser-header .setting-more .more-btn,
.fake-browser .fake-browser-header .setting-more .more-btn:before,
.fake-browser .fake-browser-header .setting-more .more-btn:after {
  display: inline-block;
  height: 3px;
  width: 20px;
  border-radius: 2px;
  background-color: #999;
  vertical-align: middle;
}

.fake-browser .fake-browser-header .setting-more .more-btn:before,
.fake-browser .fake-browser-header .setting-more .more-btn:after {
  content: "";
  position: absolute;
  left: 0;
}

.fake-browser .fake-browser-header .setting-more .more-btn:before {
  top: -6px;
}

.fake-browser .fake-browser-header .setting-more .more-btn:after {
  bottom: -6px;
}

.fake-browser .fake-window-body {
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #262951 !important;
}

.fake-browser .fake-window-body iframe {
  width: 100%;
  height: 100%;
}

.blinking-cursor {
  content: "";
  /* Remove display: inline-block if not required to be on the same line as text etc */
  display: inline-block;
  background-color: #d6d6d6;
  vertical-align: top;
  margin-top: 5px;
  width: 8px;
  height: 14px;
  margin-left: 5px;
  -webkit-animation: blink 1s step-end infinite;
  animation: blink 1s step-end infinite;
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
